import React, { useEffect } from 'react'
import Image from 'next/image'
import RightArrowIcon from '../../../public/static/arrow-right-small-dark.svg'
import PromoIcon from '../../../public/static/promo.svg'
import TrashIcon from '../../../public/static/trash.svg'
import { useRecoilState } from 'recoil'
import {
  isOpenWalletState,
  promoState,
  quantityState,
  selectedProductState,
} from '../../../common/atoms/checkout'
import { useUser } from '../../../hooks/users/useUser'
import { useBlockPromoCode } from '../../../hooks/promos/useBlockPromoCode'
import { useDeletePromoCode } from '../../../hooks/promos/useDeletePromoCode'
import PaymentInfo from './PaymentInfo'
import { useGetPromoCodesCampaigns } from '../../../hooks/stripe/useGetPromoCodesCampaigns'
import { clearStoredPromoCodeV2 } from '../../../hooks/cache/promo-code-storage-v2'
import QuantityInputV3 from '../../inputs/QuantityInputV3'
import { usePromoCode } from '../../../hooks/promos/usePromoCode'

interface Props {
  theme?: 'light' | 'dark'
  hideQuantity?: boolean
}

export default function CreditCardSectionV2({
  theme = 'dark',
  hideQuantity = false,
}: Props) {
  const [promoCode, setPromoCode] = useRecoilState(promoState)
  const [, setIsOpenWallet] = useRecoilState(isOpenWalletState)
  const { refetch } = useUser()
  const { mutate: deletePromoCode, isSuccess } = useBlockPromoCode()
  const { mutate: deleteReferralCode, isSuccess: isDeleteReferralCodeSuccess } =
    useDeletePromoCode()
  const { refetch: refetchPromoCodes } = useGetPromoCodesCampaigns()
  const { hasPromoCode, promoCodeWarning, hasPromoCodeAndWarning } =
    usePromoCode()
  const [quantity, setQuantity] = useRecoilState(quantityState)
  const [product] = useRecoilState(selectedProductState)

  useEffect(() => {
    console.log(product)
    if (product?.slug.includes('black-friday') && quantity > 1) {
      setQuantity(1)
    }
  }, [product])

  useEffect(() => {
    if (isSuccess) {
      refetch()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isDeleteReferralCodeSuccess) {
      refetch()
    }
  }, [isDeleteReferralCodeSuccess])

  return (
    <div
      className={'flex select-none flex-col'}
      onClick={(e) => {
        e.preventDefault()
        setIsOpenWallet(true)
      }}
    >
      <div className={'flex flex-col gap-4'}>
        <div
          className={
            'items-center flex cursor-pointer flex-row justify-between'
          }
        >
          <div className={'w-full'}>
            <PaymentInfo theme={theme} />
          </div>

          <div className={'relative h-3 w-4'}>
            <Image
              src={RightArrowIcon}
              fill={true}
              className={'object-contain'}
              alt={'Right arrow icon'}
            />
          </div>
        </div>

        {hasPromoCode() && (
          <div className={'flex flex-col'}>
            <div className={'mt-1 flex flex-row items-center justify-between'}>
              <div className={'flex flex-row items-center'}>
                <div className={'relative h-6 w-6 rounded-full'}>
                  <Image
                    src={PromoIcon}
                    className={'rounded-full object-contain'}
                    fill={true}
                    alt={'Promo icon'}
                  />
                </div>

                <p
                  className={`text-body-semibold ${
                    theme === 'dark' ? 'text-color-gray5' : 'text-darul-900'
                  } ml-2`}
                >
                  {promoCode?.type && promoCode.type.includes('referrals')
                    ? 'Referral Code Applied'
                    : 'Promo Code Applied'}
                </p>
              </div>

              <div className={'relative h-6 w-6 cursor-pointer'}>
                <Image
                  src={TrashIcon}
                  fill={true}
                  className={'object-contain'}
                  alt={'Trash icon'}
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    if (promoCode) {
                      if (promoCode.type === 'referral_game') {
                        deleteReferralCode(promoCode.id)
                      } else if (promoCode.type === 'marketing') {
                        deletePromoCode(promoCode.id)
                      } else if (promoCode.type === 'campaign') {
                        refetchPromoCodes()
                      }
                    }

                    clearStoredPromoCodeV2()
                    setPromoCode(null)
                  }}
                />
              </div>
            </div>

            <p className={'ml-8 mt-1 text-xs font-normal text-[#1DCEA4]'}>
              {promoCode?.name}
            </p>
            <div
              className={`${
                hasPromoCodeAndWarning() ? 'visible' : 'hidden'
              } mt-2 rounded-xl bg-[#FF354D33] bg-opacity-25 p-2`}
            >
              <p className={'text-normal text-lexend text-xs text-[#FF354D]'}>
                {hasPromoCodeAndWarning() ? promoCodeWarning() : 'error'}
              </p>
            </div>
          </div>
        )}

        {!hideQuantity &&
          product?.slug &&
          !product.slug.includes('black-friday') && (
            <div className={'-mt-3'}>
              <QuantityInputV3 min={1} max={5} theme={theme} />
            </div>
          )}
      </div>
    </div>
  )
}
